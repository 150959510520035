import { http } from './config';

export default{
    brands:() => {
        return http.get('brands')
    },
    modelNames:(idBrand) => {
        return http.get('models/' + idBrand)
    },
    codes:(idBrand,model_name) => {
        return http.get('models/' + idBrand + '/' + model_name)
    }, 
    store:(watch) =>{
        return http.post('watch', watch)
    },
    list:() => {
        return http.get('watch')
    },
    delete:(id) => {
        return http.delete('watch/' + id)
    },
    update:(watch) =>{
        return http.put('watch/' + watch.watch.id, watch)
    },
    show:(id) => {
        return http.get('watch/' + id)
    },
    transfer:(watchTransfer) => {
        return http.post('watchtransfer', watchTransfer)
    },
    transferList:() => {
        return http.get('watchtransfer')
    },
    transferUpdate:(form) => {
        return http.put('watchtransfer/' + form.watchTransfer.id, form)
    },
    report:(form) => {
        return http.post('occurrence',form)
    },
    reportList:() => {
        return http.get('occurrence')
    },
    searchWatchStatus:(form) =>{
        return http.post('searchwatchstatus', form)
    }
}