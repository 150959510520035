import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: { 
        userLogged: null,
    },
    mutations: {
        setUserLogged(state, payload){
            state.userLogged = payload
        },
    }
})

export default store