<template>
    <!-- INICIO MAIN ------------------------------------- -->
    <section class="">
        <!-- <?php include('parts/navs/nav-sidebar.php'); ?> -->
        <!-- INICIO CONTENT -->
        <div class="col p-0" ref="topPage">
            <div class="d-md-flex justify-content-between align-items-center mb-3">
                <h2 class="mb-0">Novo relógio seguro</h2>
            </div>
            <div class="container p-0">
                <div v-if="hasErrors" class="alert alert-danger" role="alert">
                    <div v-for="(v, k) in errors" :key="k">
                        <small>{{v.join(' ')}}</small>
                    </div>
                </div>
                <div class="row">
                  <div class="col">
                    <form @submit.prevent="store()">
                        <!-- inputs -->
                        <div v-if="!hasNewBrand">
                            <div class="form-floating mb-2">
                                <select v-model="form.watch.id_brand" class="form-control form-control-sm" required>
                                    <option value="">Selecione uma Marca</option>
                                    <option v-for="brand in brands" :key="brand.id" v-bind:value="brand.id">
                                        {{brand.name}}
                                    </option>
                                </select>
                                <small><a v-on:click="suggestBrand()" style="cursor: pointer;">Não Encontrou a Marca? Clique Aqui</a></small>
                            <label for="floatingText">Marca</label>
                            </div>
                        </div>
                        <div v-if="hasNewBrand">
                            <div class="form-floating mb-2">
                                <input v-model="form.watch.newBrand" type="text" maxlength="128" class="form-control form-control-sm" required autofocus>
                                <small><a v-on:click="suggestBrand()" style="cursor: pointer;">Cancelar</a></small>
                            <label for="floatingText">Nova Marca</label>
                            </div>
                        </div>
                        <div v-if="!hasNewModel">
                            <div class="form-floating mb-2">
                                <select v-model="form.watch.model_name" class="form-control form-control-sm" required>
                                    <option value="">Selecione um Modelo</option>
                                    <option v-for="model in modelNames" :key="model.name" v-bind:value="model.name">
                                    {{model.name}}
                                    </option>
                                </select>
                                <small><a v-on:click="suggestModel()" style="cursor: pointer;">Não Encontrou o Modelo? Clique Aqui</a></small>
                            <label for="floatingText">Nome do modelo</label>
                            </div>
                        </div>
                        <div v-if="hasNewModel">
                            <div class="form-floating mb-2">
                                <input v-model="form.watch.newModel" type="text" maxlength="128" class="form-control form-control-sm" required autofocus>
                            <label for="floatingText">Novo Nome de Modelo</label>
                            </div>
                        </div>
                        <div v-if="!hasNewModel">
                            <div class="form-floating mb-2">
                                <select v-model="form.watch.id_model" class="form-control form-control-sm" required>
                                    <option value="">Selecione um Código de Referência</option>
                                    <option v-for="model in models" :key="model.id" v-bind:value="model.id">
                                        {{model.code}} - {{model.description}}
                                    </option>
                                </select>
                            <label for="floatingText">Código de referência</label>
                            </div>
                        </div>
                        <div v-if="hasNewModel">
                            <div class="form-floating mb-2">
                                <input v-model="form.watch.newModelCode" type="text" maxlength="128" class="form-control form-control-sm" required autofocus>
                            <label for="floatingText">Novo Código de Referência</label>
                            </div>
                            <div class="form-floating mb-2">
                                <input v-model="form.watch.newModelDescription" type="text" maxlength="128" class="form-control form-control-sm" required autofocus>
                                <small><a v-on:click="suggestModel()" style="cursor: pointer;">Cancelar</a></small>
                            <label for="floatingText">Novo Nome de Referência</label>
                            </div>
                        </div>

                        <div class="form-floating mb-2">
                            <input v-model="form.watch.serial" type="text" maxlength="128" class="form-control form-control-sm" required autofocus>
                            <label for="floatingText">Serial do exemplar</label>
                        </div>
                        <div class="cropper-wrapper">
                            <cropper
                                @change="change"
                                minWidth = 400
                                minHeight = 400
                                :src="image"
                                :stencil-props="{
                                    handlers: {},
                                    //movable: false,
                                    scalable: false,
                                    resizable: false,
                                }"
                                :stencil-size="{
                                    width: 280,
                                    height: 280
                                }"
                                image-restriction="stencil"
                            />
                        </div>
                        <div class="button-wrapper">
                            <span class="button mb-2" @click="$refs.file.click()">
                                <input type="file" ref="file" @change="loadImage($event)" accept="image/*">
                                Selecionar / Trocar foto
                            </span>
                        </div>
                        <!-- <?php include('parts/confirma-termos.php'); ?> -->
                        <div class="text-center">
                            <!-- <button type="button" class="btn btn-secondary me-1" data-bs-dismiss="modal">Cancelar</button> -->
                            <div class="col d-flex">
                                <button type="submit" class="btn btn-dark" data-bs-toggle="modal" data-bs-target="#modalNovo" >Salvar</button>
                            </div>
                            <!-- <button type="submit" class="btn btn-primary btn-sm">Salvar</button> -->
                        </div>
                    </form>
                  </div>
                  <!-- <?php include('parts/sidebar-banner.php'); ?> -->
                </div>
            </div>
        </div>
        <!-- FIM CONTENT -->
        <Modal ref="modal"/>
    </section>
    <!-- FIM MAIN  -->



</template>

<script>

import WatchRelogioSeguroAPI from '@/services/relogioseguroapi/watch'
import Modal from '@/components/Modal'
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

export default {
    directives: {
        Cropper
    },
    components: {
        Modal
    },
    data(){
        return {
            form:{
                watch:{
                    id_brand: '',
                    id_model: '',
                    model_name: '',
                    serial: '',
                    image: null,
                },
            },
            brands: [],
            modelNames: [],
            models: [],
            image: require('@/assets/images/watch_default.png'),
            imageDefault: require('@/assets/images/watch_default.png'),
            errors: null,
            newBrand: false,
            newModel: false,
        }
    },
    methods: {
        
        suggestBrand(){
            this.newBrand = !this.newBrand
            this.newModel = !this.newModel
        },
        suggestModel(){
            if(this.newBrand){
                this.newBrand = !this.newBrand
                this.newModel = !this.newModel
            }else{
                this.newModel = !this.newModel
            } 
        },
        goToTopPage(){
            let element = this.$refs['topPage'];
            let top = element.offsetTop;
            window.scrollTo(0, top);
        },
        store(){
            this.$refs.modal.isLoading = true
            WatchRelogioSeguroAPI.store(this.form).then(response => {
                console.log(response)
                this.errors = null
                this.goToTopPage()
                this.clearFields()
                this.$refs.modal.isLoading = false
                this.$refs.newBrand = false
                this.$refs.newModel = false
                this.$refs.modal.isSuccessful = true
            }).catch(e => {
                this.isSuccessful = false
                if(e.response){
                    if (Array.isArray(e.response.data.error)){
                        this.errors = e.response.data.error
                    }else{
                        this.errors = e.response.data.error
                    }
                    console.log(this.errors)
                }else{
                    this.errors = [['Ocorreu um erro desconhecido.']]
                }
                this.goToTopPage()
                this.$refs.modal.isLoading = false
            })
        },
        change({canvas}) {
            if (this.image != this.imageDefault){
                if (canvas.width > 500){
                    let img = new Image()
                    img.src = canvas.toDataURL('image/png')
                    let canvas2 = document.createElement('canvas')
                    let ctx = canvas2.getContext('2d')
                    canvas2.width = 500;
                    canvas2.height = 500;
                    ctx.drawImage(canvas, 0, 0, 500, 500)
                    this.form.watch.image = canvas2.toDataURL('image/png')
                }else{
                    this.form.watch.image = canvas.toDataURL('image/png')
                }
            }else{
                this.form.watch.image = null;
            }
            //this.$refs.cropper.refresh();
        },
        reset() {
			this.image = null;
            //this.$refs.cropper.refresh();
		},
		loadImage(event) {
			var input = event.target;
			if (input.files && input.files[0]) {
				var reader = new FileReader();
				reader.onload = (e) => {
					this.image = e.target.result;
				};
				reader.readAsDataURL(input.files[0]);
			}
            //this.$refs.cropper.refresh();
		},
        clearFields(){
            this.form.watch.serial = null
        }
    },
    computed: {
        hasErrors() {
            if(this.errors){
                return true
            }
            return false
        },
        hasNewBrand(){
            return this.newBrand
        },
        hasNewModel(){
            return this.newModel
        },
        hasNewModelCode(){
            return this.newModelCode
        }
    },
    watch: {
        'form.watch.id_brand': function (newValue, /*oldValue*/) {
            if (newValue){
                this.$refs.modal.isLoading = true
                WatchRelogioSeguroAPI.modelNames(newValue).then(response => {
                    this.modelNames = response.data;

                    this.form.watch.id_model = '';
                    this.form.watch.model_name = '';
                    this.form.watch.serial = '';
                    this.form.watch.image = null;

                    this.$refs.modal.isLoading = false
                })
            } else {
                this.form.watch.id_model = '';
                this.form.watch.model_name = '';
                this.form.watch.serial = '';
                this.form.watch.image = null;
            }
        },
        'form.watch.model_name': function (newValue, /*oldValue*/) {
            if (newValue){
                this.$refs.modal.isLoading = true
                WatchRelogioSeguroAPI.codes(this.form.watch.id_brand,newValue).then(response => {
                    this.models = response.data;
                    this.$refs.modal.isLoading = false
                })
            }
        },
        'form.watch.id_model': function (newValue, oldValue) {
            if (newValue) {
                if (oldValue != newValue){
                    this.$refs.modal.isLoading = true
                    let img_path = this.models.filter(function(value){
                        return value.id == newValue
                    })[0].image_path
                    this.image = img_path
                    this.imageDefault = img_path
                    this.form.watch.image = null
                    this.$refs.modal.isLoading = false
                }
            }
        },
    },
    mounted: function () {
        this.$refs.modal.isLoading = true
        WatchRelogioSeguroAPI.brands().then(response => {
            this.brands = response.data;
            this.$refs.modal.isLoading = false
        }).catch(e => {
            console.log(e)
            this.errors = [['Ocorreu um erro desconhecido.']]
            this.goToTopPage()
            this.$refs.modal.isLoading = false
        })
    },
}
</script>

<style scoped>

.cropper-wrapper {
	overflow: hidden;
	position: relative;
	height: 400px;
	background: black;
}

.upload-example-cropper {
	border: solid 1px #EEE;
	height: 250px;
	width: 100%;
}

.button-wrapper {
	display: flex;
	/* justify-content: center; */
	margin-top: 17px;
}

.button {
	font-size: 16px;
	padding: 10px 20px;
	background-color: #dce6ed;
	cursor: pointer;
	transition: background 0.5s;
    color: #000;
    border-color: #dce6ed;
}

.button:hover {
	color: #000;
    background-color: #e1eaf0;
    border-color: #e0e9ef;
}

.button input {
	display: none;
}



</style>