<template>
    <!-- <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <h1>Esqueceu a senha?</h1>
                <div class="card">
                    <div class="card-body">
                        <div v-if="hasErrors" class="alert alert-danger" role="alert">
                            <div>
                                <small>{{errorText}}</small>
                            </div>
                        </div>
                        <label class="col-md-12 col-form-label">E-Mail</label>
                        <div class="col-md-12">
                            <input id="email" v-model="form.user.email" type="text" class="form-control form-control-sm" name="email" required autofocus>
                        </div>
                        <div class="col-md-12 mt-2">
                            <button v-on:click.prevent="forgotPassword()" :disabled='isDisabledLogin' class="btn btn-primary btn-sm">Enviar e-mail de redefinição de senha</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modal ref="modal" />
    </div> -->

        <div class="row justify-content-center rs-seguranca-mob gap-2">
            <!-- INICIO CONTENT -->
            <div class="col rs-login p-0 p-md-4 px-lg-5 rounded text-center">
                <h2 class="display-6 mb-3">Esqueceu a senha?</h2>
                <div class="px-md-5">
                    <div v-if="hasErrors" class="alert alert-danger" role="alert">
                        <div>
                            <small>{{errorText}}</small>
                        </div>
                    </div>
                    <div class="form-floating col mb-2">
                        <input type="email" class="form-control border-0" id="floatingEmail" placeholder="usuario@dominio.com.br" v-model="form.user.email" required autofocus>
                        <label for="floatingEmail">Email</label>
                    </div>
                    <button v-on:click.prevent="forgotPassword()" :disabled='isDisabledLogin' class="btn btn-primary btn-lg">Enviar e-mail de redefinição de senha</button>
                </div>
            </div>

            <Modal ref="modal" />

        </div>
    <!-- FIM CONTENT -->  
</template>

<script>

import Modal from '@/components/ForgotPasswordModal'
import UserRelogioSeguroAPI from '@/services/relogioseguroapi/user'

export default {
    data(){
        return {
            form: { 
                user: {
                    email: '',
                },
            },
            errorText: ''
        }
    },
    components: {
        Modal
    },
    methods: {
        forgotPassword(){
            this.$refs.modal.isLoading = true
            UserRelogioSeguroAPI.forgotPassword(this.form).then(response => {
                console.log(response)
                this.errorText = ''
                this.$refs.modal.isLoading = false
                this.$refs.modal.isSuccessful = true
            }).catch(e => {
                console.log(e)
                this.errorText = 'Não foi possível enviar o e-mail com a redefinição de senha, verifique o e-mail digitado.'
                this.$refs.modal.isLoading = false
            })            
        }
    },
    computed: {
        isDisabledLogin(){
            if (this.form.user.email.trim()){
                 return false;
            }
            return true;
        },
        hasErrors(){
            if(this.errorText){
                return true
            }
            return false
        }
    }
}
</script>