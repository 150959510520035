import axios from 'axios';
import router from '@/routes'

const http = axios.create({
    //baseURL: 'http://localhost:8000/api/'
    baseURL: 'https://api.relogioseguro.com/api/'
})

http.interceptors.request.use(function (config) {
    let token = localStorage.getItem('access_token');
    if(token){
        config.headers.Authorization = 'Bearer ' + token;
    }
    return config;
})

http.interceptors.response.use(function (response) {
    return response
}, function (error) {
    if (error.response.status == 401) {
        localStorage.removeItem('access_token')
        localStorage.removeItem('userLogged')
        if(router.currentRoute.name != null && router.currentRoute.name != 'login' && router.currentRoute.name != 'register' && router.currentRoute.name != 'resetpassword' && router.currentRoute.name != 'confirmmail' && router.currentRoute.name != 'partners'){
            location.reload()
            //router.push('/')
        }else{
            return Promise.reject(error)
        }
    }else{
        return Promise.reject(error)
    }
})

//export const http
export {http}
