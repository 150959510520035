<template>
    <div v-if="show">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-dialog modal-md">
                        <div class="modal-content">
                            <div class="modal-body" style="padding: 0.5rem;">
                                <div class="d-flex justify-content-center mb-3 mt-2">
                                    <i class="fa fa-times-circle text-danger fa-3x"></i>
                                </div>
                                <div class="d-flex justify-content-center mb-1 ps-2 pe-2">
                                    {{ text }}
                                </div>
                            </div>
                            <div class="row p-0 px-md-4 m-0 g-2 text-center">
                                <div class="col d-flex justify-content-center">
                                    <button v-on:click="cancelar()" type="button" class="btn btn-danger btn-sm p-2">Cancelar</button>
                                </div>
                                <div class="col d-flex justify-content-center">
                                    <button v-on:click="goToAccount()" type="button" class="btn btn-dark btn-sm p-2">Editar Cadastro</button>
                                </div>
                            </div>
                            <div class="modal-footer" style="padding: 0.5rem;"></div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isVisible : false,
            text: 'Registrar uma ocorrência é um procedimento sério que pode ter implicações legais. Para registrar uma ocorrência é necessário realizar o cadastro completo.'
        }
    },
    computed: {
        show() {
            return this.isVisible
        }
    },
    methods : {
        goToAccount(){
            this.$router.push('/account');
        },
        cancelar(){
            this.isVisible = false;
        }
    },
    watch: {
        'visible': function (newValue) {
            if (newValue){
                document.getElementsByTagName('body')[0].classList.add('modal-open');
            }else{
                document.getElementsByTagName('body')[0].classList.remove('modal-open');
            }
        },
    }
}
</script>