<template>
    <div v-if="isVisible" >
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header pb-0">
                                <h6 class="modal-title text-center display-6 mb-1 w-100" id="modalOcorrenciaDetalheLabel">Ocorrência</h6>
                            </div>
                            <div class="modal-body py-0" id="modalBody"> 
                                <div v-if="hasErrors" class="alert alert-danger" role="alert">
                                    <div v-for="(v, k) in errors" :key="k">
                                        <small>{{v.join(' ')}}</small>
                                    </div>
                                </div>

                                <div class="justify-content-center">
                                    
                                    <div v-if="showWizardStepLoading" >    
                                        <div class="d-flex justify-content-center">
                                            <div class="spinner-border" role="status">
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-center">
                                            <p>{{textLoading}}</p>
                                        </div>
                                    </div>

                                    <form v-if="showWizardStepReport" @submit.prevent="report()">

                                        <div class="card rs-card-relogio rs-card-relogio-sm mb-2">
                                            <div class="row g-0">
                                                <!-- foto -->
                                                <div class="col-4 col-md-2">
                                                    <div class="position-absolute top-0 start-0 p-1"><i class="bi bi-patch-check-fill rs-icone"></i></div>
                                                    <div class="bg-light rounded-start h-100 rs-img" v-bind:style="{ backgroundImage: 'url(' + (watch.images.length > 0 ? watch.images[0].path : (watch.model.image_path ? watch.model.image_path : require('@/assets/images/watch_default.png'))) + ')' }">
                                                        <div class="bg-light rounded-start h-100 rs-img" v-bind:style="{ backgroundImage: 'url(' + (watch.images.length > 0 ? watch.images[0].path : (watch.model.image_path ? watch.model.image_path : require('@/assets/images/watch_default.png'))) + ')' }"></div>
                                                    </div>
                                                </div>
                                                
                                                <div class="col">
                                                    <div class="card-body container p-2 h-100">
                                                        <div class="row h-100">
                                                            <!-- infos -->
                                                            <div class="col position-relative">
                                                                <h6 class="card-title m-0">{{watch.brand.name}} - {{watch.model.name}}</h6>
                                                                <p class="card-text mb-3 m-lg-0"><small class="text-muted">{{watch.model.code}}</small></p>
                                                                <!-- <p class="card-text mb-3 m-lg-0"><small class="text-dark">{{watch.model.description}}</small></p> -->
                                                                <p class="card-text bottom-0 text-dark position-absolute bottom-0">
                                                                    <small>{{watch.serial}}</small>
                                                                </p> 
                                                            </div>
                                                        </div>                    
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                    
                                        
                                        <div class="form-floating mb-2">
                                                <select v-model="form.occurrence.id_occurrence_type" class="form-control">
                                                    <option value="">Selecione um Tipo</option>
                                                    <option v-for="occurrenceType in occurrenceTypes" :key="occurrenceType.id" v-bind:value="occurrenceType.id">
                                                        {{occurrenceType.name}}
                                                    </option>
                                                </select>
                                                <label>Tipo</label>
                                        </div>
                                        
                                        <div class="form-floating mb-2">
                                                <input required class="form-control" type="date" v-model="form.occurrence.occurrence_date" :max="now()">
                                                <label>Data</label>
                                        </div>
                                        
                                        <div class="form-group mb-2">
                                                <label>Descreva a ocorrência</label>
                                                <textarea class="form-control" v-model="form.occurrence.description" maxlength="2048" rows="2" id="floatingTextarea2"></textarea>
                                        </div>

                                        <div class="form-group mb-2">
                                            <label>Boletim de ocorrência</label>
                                            
                                            <div class="">
                                                <input type="file" id="file" ref="myFiles" class="custom-file-input" @change="change" multiple>
                                            </div>

                                        </div>

                                        <div class="form-group mb-2">
                                            <label>Políticas de privacidade</label>
                                            <textarea readonly class="form-control" rows="5" style="margin-top: 0px; margin-bottom: 0px; height: 135px;">
                                                POLÍTICA DE PRIVACIDADE E PROTEÇÃO DE DADOS PESSOAIS DA PLATAFORMA RELÓGIO SEGURO
                                                Maio/2021

                                                A EWATCH LTDA., pessoa jurídica de direito privado inscrita no CNPJ sob o nº.
                                                37.876.900/0001-07, doravante chamada de “Relógio Seguro”, é uma empresa que entende a
                                                importância da sua privacidade e compreende a necessidade da transparência sobre a forma
                                                como conduz os processos de coleta e tratamento de seus dados pessoais.
                                                Assim, esta Política de Privacidade e Proteção de Dados Pessoais (“Política de Privacidade” ou
                                                “Política”) foi pensada e elaborada, em conformidade com a Lei nº 13.709/2018 (“Lei Geral de
                                                Proteção de Dados” ou “LGPD”), a Lei nº 12.965/2014 (“Marco Civil da Internet”) e demais leis
                                                aplicáveis, com o objetivo principal de oferecer informações sobre:
                                                1. Definições essenciais;
                                                2. Dados Pessoais coletados;
                                                3. Tratamentos de Dados Pessoais e suas Finalidades;
                                                4. Bases legais para os Tratamentos de Dados Pessoais;
                                                5. Locais e Prazos de Armazenamento dos Dados Pessoais;
                                                6. Medidas de Segurança Adotadas;
                                                7. Compartilhamento dos Dados Pessoais;
                                                8. Direitos dos Titulares dos Dados Pessoais;
                                                9. Atualizações e alterações na Política de Privacidade;
                                                10. Contato e Dúvidas;
                                                11. Foro competente.
                                                Esta Política de Privacidade abrange os dados pessoais coletados e/ou tratados na
                                                administração da plataforma de consulta e compartilhamento de informações de propriedade da
                                                Relógio Seguro (“Plataforma Relógio Seguro” ou “Plataforma”).
                                                Esclarecemos que esta Política é um documento complementar aos Termos de Uso da
                                                Plataforma Relógio Seguro, e por isso deverá ser interpretada em conjunto com aquele
                                                documento, no qual inclusive estão descritas as regras de utilização e as responsabilidades dos
                                                Usuários na utilização da Plataforma. Desta forma, caso você tenha se cadastrado para utilizar a
                                                Plataforma Relógio Seguro ou tenha navegado por ela, esta Política de Privacidade se aplica
                                                aos seus dados pessoais tratados pela Relógio Seguro.
                                                O fornecimento dos seus dados pessoais pressupõe a aceitação da presente Política. Portanto,
                                                caso não concorde com ela, solicitamos que não nos forneça seus dados pessoais nem utilize a
                                                Plataforma.
                                                1. Definições essenciais:
                                                Para o seu esclarecimento e melhor compreensão dessa Política, nós adotamos a definição de:

                                                I. Dados pessoais como qualquer informação sobre pessoa natural identificada ou
                                                identificável. Desta forma, dados anonimizados não serão considerados dados pessoais;
                                                II. Dados pessoais sensíveis como qualquer dado pessoal sobre origem racial ou étnica,
                                                convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter
                                                religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou
                                                biométrico, quando vinculado a uma pessoa natural;
                                                III. Titular dos dados pessoais como a pessoa natural a quem os dados pessoais se
                                                referem. Para os efeitos dessa Política, é o Usuário ou Visitante da Plataforma;
                                                IV. Tratamento de dados como toda operação realizada com dados pessoais, como as que
                                                se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução,
                                                transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação,
                                                avaliação ou controle da informação, modificação, comunicação, transferência, difusão
                                                ou extração;
                                                V. Controlador de dados pessoais como pessoa física ou jurídica a quem compete as
                                                decisões referentes ao tratamento de dados pessoais. Para os efeitos dessa Política, o
                                                controlador é a Relógio Seguro;
                                                VI. Operador de dados pessoais como pessoa física ou jurídica, de direito público ou
                                                privado, que realiza o tratamento de dados pessoais em nome do controlador;
                                                VII. Sub-operador ou sub-processador de dados pessoais como um operador de dados
                                                pessoais contratado pelo operador de dados pessoais para auxiliar no cumprimento das
                                                finalidades estabelecidas nesta Política. Os Sub-operadores poderão incluir terceiros ou
                                                parceiros do operador, excluindo todos os seus funcionários que são considerados seus
                                                representantes e atuam como Operador;
                                                VIII. Autoridade Nacional de Proteção de Dados – ANPD como o órgão da Presidência da
                                                República responsável pela fiscalização do cumprimento da LGPD;
                                                IX. Encarregado de proteção de dados como pessoa física ou jurídica indicada pelo
                                                Controlador e Operador para atuar como canal de comunicação entre o Controlador, os
                                                titulares de dados, a ANPD e terceiros em geral;
                                                X. Usuário como a pessoa física que, cadastrada com login e senha, utiliza a Plataforma
                                                Relógio Seguro; e
                                                XI. Visitante como a pessoa física que navega pela Plataforma, ainda que sem realizar
                                                cadastro para utilização das suas funcionalidades.
                                                2. Dados Pessoais coletados:
                                                A Relógio Seguro possui competência para tomar decisões referentes ao tratamento de dados
                                                pessoais na administração da Plataforma e, portanto, assume o papel de Controladora de Dados
                                                Pessoais.
                                                Na Plataforma Relógio Seguro, você poderá fornecer por conta própria dados pessoais para
                                                realização de procedimentos específicos.

                                                 Em caso de realização do cadastro básico na Plataforma para fins de consulta a situações
                                                de relógios, são tratados dados pessoais como nome de usuário, endereço de e-mail e
                                                senha de acesso;
                                                 Em caso de realização do cadastro completo na Plataforma para fins de criação de um
                                                “cofre” virtual, no qual constarão informações referentes a relógios de propriedade
                                                do Usuário, são tratados dados pessoais como nome completo, CPF e telefone/celular;
                                                 Para a realização do cadastro das informações sobre cada relógio, são tratados alguns
                                                dados do relógio que podem ser utilizados para identificar o titular e, portanto, se
                                                caracterizam como dados pessoais. Esses dados são: marca do relógio; linha, modelo ou
                                                apelido; referência e serial (código de identificação exclusivo do relógio);
                                                 Para a realização do registro de perdas, furtos e roubos, são tratadas as informações como
                                                tipo de evento, localização, data e horário da ocorrência.
                                                2.1. Coleta automática de dados:
                                                A Plataforma Relógio Seguro utiliza cookies e outras tecnologias de extração automática de
                                                informações para coletar dados técnicos do dispositivo do Usuário, como por exemplo número
                                                de endereço de protocolo de internet (IP), tipo de dispositivo, tipo e versão do sistema
                                                operacional, identificadores exclusivos do dispositivo, navegador, idioma do navegador, domínio
                                                e outros dados de sistemas e tipos de plataforma, além de estatísticas sobre a utilização e
                                                interação do Usuário na Plataforma.
                                                2.2. Coleta de Dados Pessoais Sensíveis:
                                                A Relógio Seguro não coleta intencionalmente dados pessoais sensíveis por meio da
                                                Plataforma. Entretanto, caso você opte por, voluntariamente, informar algum dado pessoal
                                                sensível, o fornecimento deste dado implica na ciência dos termos e condições constantes nesta
                                                Política de Privacidade, concedendo desde já o seu livre e inequívoco consentimento para o
                                                tratamento de seus dados pessoais para as finalidades aqui determinadas.
                                                2.3. Coleta de Dados Pessoais de Crianças e Adolescentes:
                                                A Relógio Seguro não coleta consciente ou intencionalmente dados pessoais de menores de 18
                                                (dezoito), tento em vista que a Plataforma não é destinada nem dirigida a pessoas menores de
                                                18 (dezoito) anos.
                                                Dessa forma, caso a Relógio Seguro encontre ou identifique algum dado pessoal de menores de
                                                18 (dezoito) anos em seu banco de dados, sem a devida autorização do seu responsável legal,
                                                esse será excluído de forma segura e definitiva.
                                                3. Tratamentos de Dados Pessoais e suas Finalidades:
                                                Na condição de Controlador de Dados Pessoais, a Relógio Seguro é responsável por determinar
                                                quais serão e de que forma ocorrerão os tratamentos dos dados pessoais, para quais finalidades

                                                esses dados serão tratados, bem como, quais bases legais serão utilizadas para sustentar os
                                                tratamentos desses dados.
                                                No caso de tratamentos de dados pessoais coletados por meio da Plataforma, a Relógio Seguro
                                                recepciona, analisa, avalia, armazena, arquiva e utiliza os seus dados para garantir o acesso
                                                individualizado e seguro do Usuário à Plataforma e as suas funcionalidades, para personalizar e
                                                melhorar a experiência do Usuário na Plataforma e efetuar comunicação com o usuário para fins
                                                informativos ou comerciais.
                                                Também tratamos os dados pessoais para fins de constituição de um banco de informações a
                                                respeito do status de cada relógio cadastrado na Plataforma. Em hipótese alguma além das
                                                explicitadas no item 7 desta Política, porém, identificaremos perante terceiros o Usuário
                                                responsável pelo cadastro como proprietário de um relógio, ficando disponível apenas as
                                                informações relativas ao serial e status do bem em questão.
                                                4. Bases legais para os Tratamentos de Dados Pessoais:
                                                Para a realização dos tratamentos de dados pessoais coletados pela Plataforma e controlados
                                                pela Relógio Seguro, a LGPD dispensa o consentimento dos titulares. Isso porque tratamos os
                                                seus dados de acordo com as seguintes bases legais:
                                                a) execução de contrato ou procedimentos preliminares relacionados a contrato do qual seja
                                                parte o titular (art. 7 º, V da LGPD), nos casos dos seus dados cadastrais e informações
                                                apontadas na utilização da Plataforma, a fim de viabilizar o acesso e uso das funcionalidades;
                                                b) exercício regular de direitos em processo judicial, administrativo ou arbitral (art. 7º, VI, da
                                                LGPD), nos casos de armazenamento das suas informações pelos prazos previstos em lei;
                                                c) legítimo interesse do controlador ou de terceiros (art. 7º, IX da LGPD), nos casos de
                                                comunicações comerciais, promoção das nossas atividades e produtos, geração de relatórios
                                                para fins de aprimoramento dos serviços prestados pela Plataforma;
                                                d) cumprimento de obrigação legal ou regulatória (art. 7º, I da LGPD), nos casos de guarda das
                                                informações indicadas no Marco Civil da Internet.
                                                5. Locais e Prazos de Armazenamento dos Dados Pessoais:
                                                Os dados pessoais coletados pela Plataforma e controlados pela Relógio Seguro são
                                                armazenados na própria plataforma, por meio do serviço de armazenamento em nuvem
                                                HostGator, por prazo razoável e legítimo, de acordo com os prazos legais e a finalidade de cada
                                                tratamento.
                                                Findos o prazo estabelecido e a necessidade legal, os dados pessoais serão excluídos com uso
                                                de métodos de descarte seguro, ou passarão por um processo de anonimização.
                                                Caso haja solicitação do Usuário, os dados poderão ser apagados antes do prazo interno de
                                                eliminação estabelecido para o cumprimento de determinada finalidade. No entanto, pode
                                                ocorrer de os dados precisarem ser mantidos no banco ou venham a ser tratados por motivo de
                                                exercício regular de direitos, disposição legal, ordem judicial, prevenção à fraude, proteção ao
                                                crédito e outros interesses legítimos – e se esse for o caso, você será informado acerca da não
                                                eliminação dos dados cuja exclusão foi solicitada.

                                                Por fim, é importante ressaltar que na medida do permitido ou exigido por lei, a Relógio Seguro
                                                pode eliminar as suas informações pessoais a qualquer momento por critérios próprios de não
                                                conformidade, não havendo o dever de guarda delas.
                                                6. Medidas de Segurança Adotadas:
                                                A Relógio Seguro utiliza sistemas e procedimentos que atendem a padrões razoáveis de
                                                precaução e cuidado, considerando-se as possibilidades técnicas e economicamente razoáveis
                                                da tecnologia aplicável à internet, como o uso de firewall e criptografia de dados para proteção
                                                contra acessos indevidos, entre outras medidas.
                                                A Relógio Seguro não vende, nem realiza qualquer forma de comercialização de dados pessoais
                                                com terceiros.
                                                Para manutenção da sua privacidade, todos os membros da Relógio Seguro que porventura
                                                tenham acesso às suas informações e dados pessoais são informados sobre a natureza sigilosa,
                                                além de manter o compromisso de atuar totalmente em concordância com a presente Política de
                                                Privacidade.
                                                Você reconhece, neste ato que não há a possibilidade de segurança total na rede mundial de
                                                computadores, principalmente com relação a invasões criminosas às redes seguras de dados
                                                por hackers, entre outros cenários de incidentes de segurança. Apesar disso, a Relógio Seguro
                                                se compromete a tomar as providências razoáveis para proteger e impedir o acesso não
                                                autorizado aos seus dados. Contudo, a Relógio Seguro não poderá ser sancionada pelos atos
                                                daqueles que porventura obtenham acesso não autorizado aos seus dados.
                                                Ocorrendo quaisquer incidentes de segurança que acarretem a exposição ou o acesso não
                                                autorizado dos seus dados pessoais, a Relógio Seguro publicará um aviso informativo na
                                                Plataforma e/ou te encaminhará um e-mail informando as circunstâncias do ocorrido. Serão
                                                cumpridas todas as obrigações legais e previstas nas regulamentações da Autoridade Nacional
                                                de Proteção de Dados.
                                                7. Compartilhamento dos Dados Pessoais:
                                                Para a realização e efetivação das finalidades descritas nesta Política de Privacidade, a Relógio
                                                Seguro poderá realizar o compartilhamento dos seus dados pessoais com outros Usuários e
                                                agentes de tratamento de dados. Esse compartilhamento dos dados pessoais será sempre
                                                realizado em respeito aos princípios da boa-fé, finalidade, adequação, necessidade, livre acesso,
                                                qualidade dos dados, transparência, segurança, prevenção, não discriminação e
                                                responsabilização e prestação de contas. A Relógio Seguro não vende, nem realiza qualquer
                                                forma de comercialização de dados pessoais com terceiros.
                                                A Relógio Seguro pode compartilhar internamente os seus dados pessoais com pessoas cuja
                                                função dentro da empresa exija o acesso a eles. Ademais, a utilização de Operadores e/ou
                                                serviços de terceiros também pode levar ao compartilhamento dos seus dados pessoais, como
                                                por exemplo a utilização dos serviços de armazenamento em nuvem e intermediação de
                                                pagamentos.

                                                No decorrer do tratamento de dados, a Relógio Seguro eventualmente poderá compartilhar os
                                                seus dados pessoais de maneira diferente das descritas neste capítulo caso haja o seu
                                                consentimento expresso para tanto.
                                                7.1. Compartilhamento com Autoridades:
                                                Os dados pessoais coletados por meio da Plataforma e controlados pela Relógio Seguro, bem
                                                como os registros dos tratamentos, poderão ser compartilhados com autoridades judiciais ou
                                                administrativas competentes, sempre que houver determinação legal, requisição por autoridade
                                                administrativa ou ordem judicial.
                                                7.2. Transferência internacional:
                                                Qualquer compartilhamento de dados será feito sempre dentro dos limites e propósitos dos
                                                tratamentos descritos na presente Política de Privacidade, sendo possível a transferência
                                                internacional de dados quando houver a utilização softwares e/ou servidores de terceiros que
                                                armazenam dados fora do país. Nestes casos, havendo transferência de dados pessoais para
                                                fora do Brasil, serão tomadas todas as providências e cuidados necessários para a proteção dos
                                                seus dados pessoais, em observâncias aos dispositivos do Capítulo V da LGPD.
                                                8. Direitos do Titular dos Dados Pessoais:
                                                A Relógio Seguro respeita os seus direitos como titular de dados pessoais previstos no art. 18 da
                                                LGPD e garante que você poderá solicitar a qualquer momento:
                                                I. Confirmação do tratamento dos dados: a confirmação da existência de tratamento de
                                                seus dados pessoais;
                                                II. Acesso aos dados: o acesso aos seus dados pessoais por nós tratados;
                                                III. Correção de dados: a correção de seus dados pessoais incompletos, inexatos ou
                                                desatualizados;
                                                IV. Anonimização, bloqueio e eliminação dos dados: a anonimização, bloqueio ou
                                                eliminação de seus dados pessoais considerados desnecessários, excessivos ou tratados
                                                em desconformidade com o disposto na LGPD;
                                                V. Portabilidade dos dados: a portabilidade dos dados pessoais para outro fornecedor de
                                                serviço ou produto, mediante requisição expressa, de acordo com a regulamentação da
                                                autoridade nacional, observados os segredos comercial e industrial;
                                                VI. Oposição ao tratamento dos dados: a paralisação do tratamento e eliminação dos seus
                                                dados pessoais tratados com o seu consentimento, caso você se oponha ao tratamento, a
                                                sua finalidade ou a forma que está sendo realizado. O tratamento será paralisado e os
                                                dados eliminados, exceto nas hipóteses em que o tratamento foi realizado com amparo em
                                                base legal diversa do consentimento;
                                                VII. Informação sobre compartilhamento de dados: as informações a respeito de com
                                                quais entidades públicas e privadas nós realizamos uso compartilhado de dados;

                                                VIII. Informação sobre o consentimento: as informações a respeito da possibilidade de não
                                                fornecer determinados consentimentos e sobre as consequências da negativa;
                                                IX. Revogação do consentimento: a revogação do consentimento; e
                                                X. Acionamento das autoridades: o acionamento da Autoridade Nacional de Proteção de
                                                Dados ou outro órgão que possua competência para resolver questões envolvendo a
                                                proteção de dados pessoais.
                                                Ademais, a Relógio Seguro solicita que você informe quaisquer alterações em seus dados
                                                pessoais, para que os seus dados sejam atualizados e mantidos corretamente armazenados
                                                conosco.
                                                Em caso de impossibilidade de adoção imediata das providências indicadas acima, a
                                                Relógio Seguro enviará uma resposta para indicar as razões de fato ou de direito que
                                                impedem a sua adoção imediata, conforme determina o art. 18º, § 4º da LGPD.
                                                9. Atualizações e alterações na Política de Privacidade:
                                                É reservado à Relógio Seguro o direito de alterar ou atualizar a presente Política de Privacidade
                                                a qualquer momento, com o objetivo de garantir a sua privacidade e o compromisso de
                                                transparência sobre os processos de coleta e tratamento os seus dados pessoais. As
                                                modificações podem ocorrer quando houver alteração nos dados coletados, tratamentos
                                                realizados ou finalidades, quando requerido pela Autoridade Nacional de Proteção de Dados ou
                                                outro órgão competente, ou em decorrência de questionamentos ou sugestões suas.
                                                A nova versão da Política de Privacidade entrará em vigor imediatamente após a sua publicação
                                                na Plataforma. Caso você não concorde com qualquer das alterações da Política de Privacidade,
                                                poderá solicitar a interrupção do tratamento dos seus dados pessoais nos limites contratuais e
                                                legalmente possíveis e razoáveis.
                                                10. Contato e Dúvidas:
                                                Caso haja a necessidade e/ou vontade de esclarecer qualquer dúvida sobre a presente Política,
                                                obter mais informação acerca dos tratamentos dos seus dados pessoais realizados pela Relógio
                                                Seguro ou exercer algum dos seus direitos, você poderá entrar em contado com o Encarregado
                                                de proteção de dados da Relógio Seguro por meio do e-mail adm@ewatchboutique.com.
                                                11. Foro competente:
                                                Esta Política de Privacidade será regida e interpretada exclusivamente de acordo com as leis do
                                                Brasil, especialmente a Lei Geral de Proteção de Dados. Qualquer reclamação, conflito ou
                                                controvérsia que dela surgir, ou a ela for relacionada, deverá ser solucionada no foro da comarca
                                                de Salvador/BA.
                                                
                                            </textarea>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" v-model="privacyPolicy">
                                                <label class="form-check-label small">
                                                    Aceitar
                                                </label>
                                            </div>
                                        </div>

                                        <div class="form-group mb-2">
                                            <label>Termos de uso</label>
                                            <textarea readonly class="form-control" rows="5" style="margin-top: 0px; margin-bottom: 0px; height: 135px;">
                                                TERMOS E CONDIÇÕES DE USO – RELÓGIO SEGURO

                                                    Versão 1.0 – Maio/2021

                                                    Estes Termos e Condições de Uso (“Termos”) regulam a utilização pelos Usuários e Usuários do
                                                    aplicativo de consulta e compartilhamento de informações Relógio Seguro (“Plataforma Relógio
                                                    Seguro”). Os Serviços regulamentados por este instrumento são fornecidos pela Ewatch Ltda.,
                                                    pessoa jurídica de direito privado inscrita no CNPJ sob o nº. 37.876.900/0001-07 (“Relógio
                                                    Seguro”) ao Usuário.
                                                    1. DEFINIÇÕES IMPORTANTES. Para todos os efeitos, especialmente o de interpretação destes
                                                    Termos, as expressões abaixo indicadas deverão ser compreendidas com base nas seguintes
                                                    definições:
                                                    I – Conta: o perfil pessoal do Usuário, por meio do qual poderá imputar informações e fazer uso
                                                    regular da Plataforma;
                                                    II – Plataforma Relógio Seguro / Plataforma / Software: software de consulta e compartilhamento
                                                    de informações ao qual esse contrato se refere;
                                                    III – Relógio Seguro: a empresa fornecedora da Plataforma Relógio Seguro;
                                                    IV – Serviços: a disponibilização da Plataforma Relógio Seguro e a prestação de suporte técnico
                                                    necessário à sua utilização;
                                                    V – Usuário: pessoas naturais que, cadastrados com login e senha, fazem uso da Plataforma.
                                                    2. NATUREZA E EFICÁCIA DOS TERMOS. Ao fazer o cadastro na Plataforma ou, por qualquer meio,
                                                    dela se utilizar, o Usuário concorda com estes Termos, que estabelecem as regras e
                                                    procedimentos a serem observados no contexto da utilização do Software. Caso não concorde
                                                    com as regras presentes nestes Termos, o Usuário não poderá acessar e utilizar a Plataforma, uma
                                                    vez que o atendimento a elas é condição essencial para que a Relógio Seguro possa prestar de
                                                    forma satisfatória os Serviços.
                                                    2.1. O Usuário declara ter capacidade jurídica para a prática dos atos civis, isto é, deve ser maior
                                                    de 18 (dezoito) anos ou emancipado, além de não estar submetido a nenhuma das causas
                                                    incapacitantes previstas na lei civil, nem a curatela determinada pelo Poder Judiciário. Sendo o
                                                    Usuário menor de 18 (dezoito) anos, incapaz ou submetido a curatela, o responsável legal e/ou
                                                    curador responderá pelos atos praticados em seu nome.
                                                    2.2. Estes Termos poderão ser alterados a qualquer momento, sem a necessidade de autorização
                                                    prévia do Usuário, cabendo à Relógio Seguro apenas notificá-lo a respeito das modificações
                                                    realizadas, por e-mail ou alerta na sua Conta. Tais alterações entrarão em vigor imediatamente
                                                    após a publicação da versão atualizada dos Termos pela Relógio Seguro e a notificação do Usuário.
                                                    Em todo caso, caberá sempre ao Usuário verificar periodicamente a ocorrência de atualizações no
                                                    conteúdo deste documento, declarando desde já que tem conhecimento e aceita a possibilidade
                                                    de os Termos serem modificados.
                                                    2.3. A Relógio Seguro poderá deixar de oferecer ou negar acesso à Plataforma, aos Serviços ou a
                                                    qualquer parte deles, a qualquer momento e por qualquer motivo, sem necessidade de

                                                    comunicação prévia, ficando apenas obrigada a cumprir os compromissos porventura já firmados
                                                    com o Usuário.
                                                    2.4. Eventuais dúvidas, reclamações e demais comunicações relativas a estes Termos ou ao
                                                    funcionamento e/ou uso da Plataforma devem ser dirigidas pelos meios nela indicados.
                                                    3. LIMITES SUBJETIVOS DOS TERMOS. Estes Termos disciplinam as relações entre a Relógio
                                                    Seguro e o Usuário no que diz respeito ao uso da Plataforma Relógio Seguro.
                                                    3.1. A utilização da Plataforma pelo Usuário será de responsabilidade do Usuário, somente
                                                    cabendo à Relógio Seguro o papel de orientar quanto à melhor e mais correta forma de utilizá-la,
                                                    em conformidade com estes Termos, aos quais também adere, de logo, o Usuário.
                                                    3.2. Não será de responsabilidade da Relógio Seguro qualquer relação estabelecida entre o
                                                    Usuário e terceiros por meio da Plataforma, bem como seus desdobramentos.
                                                    4. DESCRIÇÃO DOS SERVIÇOS. A Plataforma Relógio Seguro funciona como um banco de dados
                                                    alimentado pelos Usuários que, a partir do seu cadastro, com a criação da Conta, reúnem e
                                                    prestam informações sobre relógios, sua autenticidade e propriedade, para apoiar proprietários,
                                                    consumidores e demais interessados nesses objetos em sua tomada de decisão, visando ser uma
                                                    ferramenta que funcione de modo a trazer maior segurança ao Usuário a respeito da idoneidade
                                                    das informações ali prestadas. Pela Plataforma, a Relógio Seguro prestará os seguintes Serviços:
                                                    I – em favor do interessado em cadastro de bens: a criação de um “cofre” virtual, no qual
                                                    constarão informações referentes a relógios de propriedade do Usuário, sem que a sua identidade
                                                    seja revelada aos demais Usuários da Plataforma;
                                                    II – em favor do interessado em consulta de bens: a análise do conteúdo do “cofre” virtual dos
                                                    Usuários, de modo a verificar a autenticidade e a indicação de proprietário de determinado relógio
                                                    no qual possua algum tipo de interesse.
                                                    4.1. O Usuário poderá acessar de forma livre e gratuita a Plataforma, porém, para que usufruir dos
                                                    Serviços deverá realizar cadastro prévio, a partir do qual será criada a Conta que lhe permitirá
                                                    usufruir das funcionalidades disponibilizadas pela Relógio Seguro.
                                                    4.1.1. Cabe ao Usuário, ao realizar seu cadastro, informar corretamente os dados exigidos pela
                                                    Relógio Seguro, comprometendo-se a inserir apenas informações verdadeiras e a informar a
                                                    Relógio Seguro acerca de qualquer alteração do seu conteúdo. O Usuário responderá
                                                    integralmente por eventuais imprecisões ou falsidades nos dados informados.
                                                    4.1.2. O Usuário é o único e exclusivo responsável pela exatidão, veracidade e atualização dos
                                                    dados informados em seu cadastro, tanto aqueles que lhe digam respeito pessoalmente ou a
                                                    terceiros, quanto aqueles relativos aos relógios cadastrados, concordando que eventuais
                                                    divergências e/ou inconsistências desses dados poderão ocasionar a não validação da sua
                                                    identidade, bem como o prejuízo do pleno funcionamento da Plataforma, o que poderá resultar
                                                    em impedimento do acesso aos Serviços, como medida de segurança.
                                                    4.1.3. O acesso à Plataforma e, em geral, aos Serviços, deverá ser realizado mediante login e senha
                                                    individuais do Usuário, a quem caberá a preservação do sigilo de tais informações de acesso.
                                                    4.1.4. O Usuário autoriza a Relógio Seguro a solicitar todos os documentos porventura necessários
                                                    à comprovação da veracidade dos dados de cadastro informados (relativos a si ou aos seus
                                                    relógios), e ainda a fazer checagem por quaisquer outros meios. O fato de a Relógio Seguro

                                                    eventualmente não solicitar tais documentos, nem realizar a checagem da veracidade dos dados
                                                    informados, não exime o Usuário da responsabilidade prevista nesta cláusula.
                                                    4.1.5. A Relógio Seguro poderá ainda, a seu critério e em conformidade com a sua Política de
                                                    Privacidade vigente, requisitar do Usuário, a qualquer tempo durante a vigência destes Termos,
                                                    outros documentos e/ou informações que julgar relevantes para a prestação dos Serviços.
                                                    4.2. A Relógio Seguro cuidará para que os Usuários interessados em consulta de relógios possam
                                                    identificar o cadastro de determinado relógio na plataforma, com o seu respectivo serial e
                                                    indicações de ocorrências relacionadas ao aludido bem (ex. roubo, extravio etc.), contudo não
                                                    possibilitará a identificação do Usuário responsável por tal cadastro, que se afirme proprietário do
                                                    relógio.
                                                    5. REGRAS DE UTILIZAÇÃO DO APLICATIVO E RESPONSABILIDADE DO USUÁRIO. O Usuário é
                                                    responsável por todos os atos praticados na e por sua Conta. Assim, responderá pelos atos a ela
                                                    vinculados, competindo-lhe fazê-los cumprir estes Termos em sua integralidade, bem como as
                                                    regras a seguir indicadas.
                                                    5.1. O Usuário não deverá em hipótese alguma ceder ou disponibilizar a terceiros os seus dados de
                                                    acesso à Plataforma. Assim, o eventual compartilhamento de tais dados será de exclusiva
                                                    responsabilidade do Usuário, estando a Relógio Seguro completamente isenta de qualquer dever
                                                    de reparar eventuais danos causados em decorrência de tal situação.
                                                    5.1.1. Em caso de extravio da senha, o Usuário deverá de imediato solicitar a sua alteração, pela
                                                    Plataforma, para evitar quaisquer prejuízos em razão desse evento.
                                                    5.2. O Usuário reconhece que há uma série de formas pelas quais a Conta e os Usuários podem
                                                    estar sob risco, como, por exemplo, o acesso ao aplicativo em um aparelho compartilhado ou
                                                    pessoal, o acesso a links duvidosos que podem conter vírus e outras mazelas virtuais. Assim, fica
                                                    estabelecido que o acesso ao aplicativo deve ser feito sempre com cuidado e em dispositivos
                                                    seguros e revestidos de instrumentos de proteção contra ações ilícitas de terceiros, pautado em
                                                    uma orientação preventiva de danos.
                                                    5.3. O Usuário se compromete a usar a Plataforma com intuito e fins lícitos, jamais promovendo
                                                    atividades criminosas e/ou ilícitas em geral por meio da tecnologia disponibilizada pela Relógio
                                                    Seguro, que deverá ser mantida indene de qualquer responsabilidade por atos ilegais,
                                                    difamatórios, discriminatórios, ofensivos, obscenos, injuriosos, caluniosos, violentos, que
                                                    constituam ameaças ou assédios de qualquer natureza, ou, ainda, que, por qualquer outro meio,
                                                    possam ferir direitos da personalidade, individuais, coletivos, fundamentais e humanos de
                                                    terceiros, e que venham a ser praticados pelo Usuário.
                                                    5.3.1. A Relógio Seguro não será responsável por danos indiretos, incidentais, especiais, punitivos
                                                    ou emergentes, lucros cessantes e quaisquer outros danos, materiais ou imateriais, relacionados,
                                                    associados ou decorrentes do uso da Plataforma pelo Usuário, ainda que tenha sido alertada para
                                                    a sua possibilidade.
                                                    6. LIMITAÇÕES DE RESPONSABILIDADE DA RELÓGIO SEGURO. A Relógio Seguro não assegura que
                                                    a Plataforma funcionará em qualquer equipamento ou dispositivo, cabendo ao Usuário adquirir e
                                                    atualizar os equipamentos e dispositivos aptos e necessários para acessá-la e utilizá-la.

                                                    6.1. Cabe ao Usuário providenciar e custear os acessos à internet e aos dispositivos necessários ao
                                                    uso do Software ou de alguma de suas funcionalidades, bem como custear as despesas referentes
                                                    a tais acessos. A Relógio Seguro não se responsabiliza por falhas na conexão do Usuário.
                                                    6.2. A Relógio Seguro não responde pelos prejuízos sofridos pelo Usuário em razão de falhas de
                                                    seus equipamentos ou de operação de suas redes, nem por eventuais bloqueios de acesso, ou
                                                    obstáculos às operações, decorrentes da desatualização das configurações de acesso e permissões
                                                    de utilização dos Usuários, cabendo ao Usuário atualizar esses dados sempre que preciso.
                                                    6.3. A Relógio Seguro não se responsabiliza por prejuízos decorrentes da violação das medidas de
                                                    segurança adotadas por terceiros que utilizem as redes públicas ou a internet, subvertendo os
                                                    sistemas de segurança para acessar as informações do Usuário.
                                                    6.4. O aplicativo poderá estar sujeito a interferências, mau funcionamento e/ou atrasos inerentes
                                                    ao uso da internet e de comunicações eletrônicas, e o Usuário declara que tem conhecimento
                                                    desses riscos, bem como que esses fatores poderão afetar de algum modo a fruição dos Serviços,
                                                    sem que lhe seja devida qualquer indenização por eventuais prejuízos, de qualquer natureza, que
                                                    venha a sofrer, a exemplo de, mas não se limitando a: danos materiais, lucros cessantes, perda de
                                                    uma chance, danos morais etc.
                                                    7. PRIVACIDADE E PROTEÇÃO DE DADOS. A Relógio Seguro se compromete a realizar os
                                                    tratamentos de dados pessoais do Usuário em respeito à Lei Geral de Proteção de Dados (Lei
                                                    13.709/2018), ao Marco Civil da Internet (Lei 12.965/2014) e às demais leis brasileiras
                                                    relacionadas à proteção dos dados pessoais, tudo nos termos da Política de Privacidade do
                                                    Software, disponível nos seus ambientes institucionais. Neste ato, o Usuário declara ter ciência do
                                                    conteúdo da Política de Privacidade, sem que tenha qualquer ressalva a opor quanto ao seu
                                                    conteúdo.
                                                    8. PROPRIEDADE INTELECTUAL. A Plataforma e todos os direitos sobre ela são de propriedade da
                                                    Relógio Seguro e assim permanecerão.
                                                    8.1. É vedado ao Usuário, sob pena de responsabilidade por qualquer prejuízo causado nesse
                                                    sentido:
                                                    I - criar trabalhos derivados baseados nos Serviços;
                                                    II - copiar ou exibir para terceiros o conteúdo e layout da Plataforma, ou mesmo reproduzir
                                                    qualquer parte ou conteúdo das suas páginas, para quaisquer fins, sem autorização expressa e
                                                    escrita da Relógio Seguro; e
                                                    III - realizar engenharia reversa dos Serviços.
                                                    8.2. A Plataforma poderá ser atualizada ou alterada a qualquer tempo, a critério da Relógio
                                                    Seguro. Caso haja qualquer alteração em relação às suas especificações técnicas, que imponha
                                                    uma alteração na metodologia de sua utilização, o Usuário será informado por e-mail ou por alerta
                                                    na Conta. As alterações realizadas poderão inclusive interferir nas funcionalidades ofertadas na
                                                    Plataforma, conforme disposto adiante nestes Termos.
                                                    9. CONDIÇÕES COMERCIAIS DOS SERVIÇOS. O Usuário declara ciência e concorda que, embora
                                                    atualmente os Serviços sejam disponibilizados de forma gratuita pela Relógio Seguro, esta se
                                                    reserva no direito de, a qualquer tempo, alterar a política de uso, descontinuar a Plataforma ou

                                                    estabelecer planos remunerados de utilização, sem a necessidade de anuência prévia, cabendo-
                                                    lhe apenas o dever de informar previamente a ação que adotará com relação aos itens
                                                    correspondentes com antecedência de 30 (trinta) dias, após o que, caso o Usuário não concorde
                                                    com as condições fixadas, terá a sua Conta encerrada e ficará impedido de usufruir dos Serviços.
                                                    Não há, pois, qualquer expectativa do Usuário de manutenção do uso da Plataforma, nem da sua
                                                    gratuidade.
                                                    10. CANCELAMENTO DA CONTA. O Usuário poderá cancelar a sua Conta a qualquer tempo,
                                                    independentemente de prévio aviso, mediante solicitação realizada diretamente pela Plataforma.
                                                    10.1. A Relógio Seguro, de seu lado, poderá encerrar a Conta do Usuário, ainda que sem motivo, a
                                                    qualquer momento, mediante o envio de notificação com antecedência mínima de 30 (trinta) dias.
                                                    Tal notificação poderá ser enviada por e-mail ao Usuário, no endereço por si cadastrado.
                                                    10.2. Será considerado motivo justo para a resolução imediata destes Termos, por parte da
                                                    Relógio Seguro, a utilização indevida da Plataforma pelo Usuário, bem como a violação das regras
                                                    previstas nestes Termos e/ou na legislação brasileira.
                                                    10.3. O cancelamento da Conta nesta hipótese não confere ao Usuário o direito de receber
                                                    multas, indenizações ou qualquer outro valor, ficando ressalvado o direito da Relógio Seguro de
                                                    ser indenizada por quaisquer danos que tenha sofrido por conta da conduta ilícita do Usuário.
                                                    11. NÃO REPÚDIO. O Usuário declara que está totalmente informado e que entende todas as
                                                    cláusulas destes Termos. Igualmente, reconhece e concorda que é o único responsável por
                                                    determinar, com base nas informações fornecidas nos ambientes institucionais da Relógio Seguro,
                                                    se a Plataforma é adequada e suficiente para os objetivos por si pretendidos. Assim, considerando
                                                    que a Plataforma funcione dentro das especificações técnicas adequadas, conforme o disposto
                                                    nestes Termos, o Usuário declara e afirma que a Relógio Seguro disponibiliza um aplicativo seguro,
                                                    autêntico, aceitável para uso e compatível com o que foi exposto nos meios de comunicação
                                                    institucional.
                                                    12. DISPOSIÇÕES GERAIS. Estes Termos devem ser interpretados em conjunto com a Política de
                                                    Privacidade da Plataforma Relógio Seguro, conforme referido acima.
                                                    12.1. Caso a Relógio Seguro deixe de exigir o cumprimento pontual e integral de qualquer das
                                                    obrigações decorrentes destes Termos, ou deixe de exercer qualquer direito ou faculdade que lhe
                                                    seja atribuída, tal fato será interpretado como mera tolerância e não importará na renúncia dos
                                                    direitos e faculdades não exercidos, nem em precedente, novação ou revogação de quaisquer
                                                    cláusulas ou condições dos presentes Termos que, para todos os efeitos, continuarão em pleno
                                                    vigor.
                                                    12.2. Caso qualquer cláusula ou condição destes Termos seja, por qualquer razão, reputada
                                                    inválida ou ineficaz, permanecerão plenamente válidas e vinculantes todas as cláusulas e
                                                    condições restantes, gerando efeitos em sua máxima extensão, como forma de alcançar a vontade
                                                    das partes.
                                                    12.3. Fica, desde logo, assegurado à Relógio Seguro o direito de regresso proveniente do
                                                    pagamento de indenizações judiciais e extrajudiciais cuja responsabilização decorra das atividades

                                                    realizadas pelo Usuário na utilização da Plataforma, sendo igualmente assegurado o direito de
                                                    denunciar a este a lide.
                                                    12.4. Não poderá o Usuário ceder, total ou parcialmente, a sua posição nestes Termos, salvo
                                                    mediante expressa autorização por parte da Relógio Seguro. De seu lado, fica a Relógio Seguro
                                                    autorizada a ceder a sua posição nestes Termos, desde que sem prejuízo à qualidade e ao escopo
                                                    dos Serviços, na hipótese de ser vendida ou submetida a qualquer processo de aquisição ou fusão,
                                                    independente de autorização prévia do Usuário.
                                                    13. LEI APLICÁVEL E FORO. Estes Termos serão regidos e interpretados exclusivamente de acordo
                                                    com as leis do Brasil. Qualquer reclamação, conflito ou controvérsia que surgir destes Termos ou a
                                                    eles seja relacionado, inclusive que diga respeito a sua validade, interpretação ou exequibilidade,
                                                    será solucionada exclusivamente na jurisdição brasileira, elegendo-se especificamente o foro da
                                                    Comarca de Salvador/BA, com renúncia a qualquer outro, por mais privilegiado que seja.
                                                

                                            </textarea>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" v-model="termsOfUse">
                                                <label class="form-check-label small">
                                                    Aceitar
                                                </label>
                                            </div>
                                        </div>                                        

                                        <div class="row p-0 px-md-4 m-0 g-2 text-center">
                                            <div class="col d-flex">
                                                <button v-on:click="hide()" type="button" class="btn btn-secondary btn-sm p-2 flex-fill">Cancelar</button>
                                            </div>
                                            <div class="col d-flex">
                                                <button type="submit" class="btn btn-dark btn-sm p-2 flex-fill">Registrar</button>
                                            </div>
                                        </div>

                                    </form>

                                    <div v-if="showWizardStepSuccessful" >    
                                        <div class="d-flex justify-content-center mb-3 mt-2">
                                            <i class="far fa-check-circle text-success fa-3x"></i>
                                        </div>
                                        <div class="d-flex justify-content-center">
                                            {{textSuccessful}}
                                        </div>
                                        
                                        <div class="row p-0 px-md-4 m-0 g-2 text-center">
                                            <div class="col d-flex">
                                                <button v-on:click="hide()" type="button" class="btn btn-dark btn-sm p-2 flex-fill">OK</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="modal-footer">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>    
</template>

<script>

import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import WatchRelogioSeguroAPI from '@/services/relogioseguroapi/watch'
import moment from 'moment'

export default {
    comments: {
        Cropper
    },
    data() {
        return {
            form:{
                occurrence:{
                    id_watch: null,
                    id_occurrence_type: '',
                    occurrence_date: null,
                    description: null,
                    files: []
                },
            },
            occurrenceTypes : [
                {id: 2, name: 'Perda'},
                {id: 3, name: 'Furto'},
                {id: 4, name: 'Roubo'},
            ],
            watch: null,
            visible : false,
            errors: null,
            currentwizardStep: 'report',
            textLoading: 'Aguarde ...',
            textSuccessful: 'Operação realizada com sucesso.',
            image: null,
            termsOfUse: false,
            privacyPolicy: false,
        }
    },
    props: {
        reportWatchSuccessful: { type: Function },
    },
    computed: {
        isVisible() {
            return this.visible
        },
        //canAddDocument(){
        //    if(this.form.occurrence.images != null){
        //        if(this.form.occurrence.images.length >= 2){
        //            return false;
        //        }
        //    }
        //    return true;
        //},
        hasErrors() {
            if(this.errors){
                return true
            }
            return false
        },
        showWizardStepReport(){
            if(this.currentwizardStep == 'report'){
                return true
            }
            return false
        },
        showWizardStepLoading(){
            if(this.currentwizardStep == 'loading'){
                return true
            }
            return false
        },
        showWizardStepSuccessful(){
            if(this.currentwizardStep == 'successful'){
                return true
            }
            return false
        },
    },
    methods : {
        now(){
           return moment().format('YYYY-MM-DD')
        },
        goToTopPage(){
            document.getElementById('modalBody').scrollTop = 0;
        },
        hide(){
            this.form.occurrence.id_occurrence_type = 2
            this.form.occurrence.occurrence_date = null
            this.form.occurrence.description = null
            this.form.occurrence.images = []
            this.file = null

            this.error = null
            this.currentwizardStep = 'report'
            this.visible = false
        },
        show(){
            this.visible = true
        },
        report(){

            this.errors = [];

            if(!this.termsOfUse){
                this.errors.push(['É necessário aceitar os Termos de Uso.'])
            }

            if(!this.privacyPolicy){
                this.errors.push(['É necessário aceitar as Políticas de Privacidade.'])
            }

            if(this.form.occurrence.id_occurrence_type !=2 ){
                if( this.form.occurrence.images == null || this.form.occurrence.images.length == 0 ){
                    this.errors.push(['É obrigatório anexar o boletim de ocorrência.'])
                }
            }

            if(this.errors.length == 0){
                this.errors = null
                this.confirmReportWatch()
            }else{
                this.goToTopPage();
            }
        },
        confirmReportWatch(){
            this.$confirm(
                {
                message: "Deseja realmente registrar essa ocorrência?",
                button: {
                    no: 'Não',
                    yes: 'Sim'
                },
                callback: confirm => {
                    if (confirm) {
                        this.currentwizardStep = 'loading'
                        WatchRelogioSeguroAPI.report(this.form).then(response => {
                            console.log(response)
                            this.errors = null
                            this.currentwizardStep = 'successful'
                            this.reportWatchSuccessful(this.watch.id)
                        }).catch(e => {
                            console.log(e)
                            if(e.response){
                                this.errors = e.response.data.error
                            }else{
                                this.errors = [['Ocorreu um erro desconhecido.']]
                            }
                            this.currentwizardStep = 'report'
                        })
                    }
                }
            })
        },
        change() {
            this.form.occurrence.images = []
            if (this.$refs.myFiles.files){
                if(this.$refs.myFiles.files.length > 2){
                    alert('Só é possível adicionar dois arquivos')
                }else{

                    this.$refs.myFiles.files.forEach(element => {
                        var reader = new FileReader();
                            reader.onload = (e) => {
                            this.file = e.target.result;
                            this.form.occurrence.images.push(e.target.result)
                            }
                            reader.readAsDataURL(element);
                    });
                }
            }
        },
        reset() {
			this.file = null;
		},
		//loadImage(event) {
		//	var input = event.target;
		//	if (input.files && input.files[0]) {
		//		var reader = new FileReader();
		//		reader.onload = (e) => {
		//			this.file = e.target.result;
		//		};
		//		reader.readAsDataURL(input.files[0]);
		//	}
		//},
        //deleteImg(index){
        //    this.form.occurrence.images.splice(index, 1);
        //}
    },
    watch: {
        'visible': function (newValue) {
            if (newValue){
                document.getElementsByTagName('body')[0].classList.add('modal-open');
            }else{
                document.getElementsByTagName('body')[0].classList.remove('modal-open');
            }
        },
    }
}
</script>