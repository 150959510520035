<template>
    <!-- <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <h1>Login</h1>
                <div class="card">
                    <div class="card-body">
                        <label class="col-md-12 col-form-label">E-Mail</label>
                        <div class="col-md-12">
                            <input id="email" v-model="form.user.email" type="text" class="form-control form-control-sm" name="email" required autofocus>
                            <span class="form-text text-danger">
                                <small>{{errorText}}</small>
                            </span>
                        </div>
                        <label class="col-md-12 col-form-label">Senha</label>
                        <div class="col-md-12">
                            <input id="password" v-model="form.user.password" @keyup.enter="login()" type="password" class="form-control form-control-sm" name="password" required>
                        </div>
                        <div class="col-md-12 mt-2">
                            <button v-on:click.prevent="login()" :disabled='isDisabledLogin' class="btn btn-primary btn-sm">Login</button>
                            <router-link to="forgotpassword" class="ml-2">Esqueceu a senha?</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Modal ref="modal" />

    </div> -->

        <div class="row justify-content-center rs-seguranca-mob gap-2">
            <!-- INICIO CONTENT -->
            <div class="col rs-login p-0 p-md-4 px-lg-5 rounded text-center">
                <h2 class="display-6 mb-3">Login</h2>

                <!-- FORM LOGIN COM EMAIL -->
                <a class="btn btn-primary d-none" href="#" role="button">Criar com meu email</a>
                    
                <!-- 
                //Exibe quando loga com email
                //Remover classe "d-none" 
                -->
                <div class="px-md-5">
                    <!-- inputs -->  
                    <div class="form-floating col mb-2">
                        <input type="email" class="form-control border-0" id="floatingEmail" placeholder="usuario@dominio.com.br" v-model="form.user.email" required autofocus>
                        <label for="floatingEmail">Email</label>
                                                    <span class="form-text text-danger">
                                <small>{{errorText}}</small>
                            </span>
                    </div>
                    <div class="form-floating col mb-2">
                        <input type="password" class="form-control border-0" id="floatingPassword" placeholder="Senha" v-model="form.user.password" @keyup.enter="login()" required>
                        <label for="floatingPassword">Senha</label>
                    </div>
                    <!-- confirma/submit -->  
                    <!-- <div class="bg-secondary p-3">CAPTCHA</div> -->
                    <div class="py-3 text-end">
                        <small><router-link to="forgotpassword" class="text-muted">Esqueceu a senha?</router-link></small>
                        <!-- <small><a href="app-dashboard.php" class="text-muted">Esqueceu a senha?</a></small> -->
                    </div>
                    <button  class="btn btn-primary btn-lg"  v-on:click.prevent="login()" :disabled='isDisabledLogin'  >Entrar</button>
                </div>

                <!-- LOGIN COM REDES -->  
                <div class="row justify-content-center">
                    <div class="col-md-10 m-0">
                        <!-- <div class="container-fluid p-0 m-0 px-sm-4 px-md-5">
                        <div class="p-2">
                            <span>ou</span>
                        </div>
                        <div class="row p-0 m-0 gap-2">
                            <div class="col p-0">
                            <a class="btn btn-secondary p-2 w-100" href="#" role="button"><img src="assets/img/ilustras/icone-facebook.svg" class="" alt=""> <small>Login</small></a>
                            </div>
                            <div class="col p-0">
                            <a class="btn btn-secondary p-2 w-100" href="#" role="button"><img src="assets/img/ilustras/icone-google.svg" class="" alt=""> <small>Login</small></a>
                            </div>
                        </div> -->
                        <!-- <div class="mt-3">
                            <p><small>Já tem uma conta? <a href="app-conta-login.php" class="ms-2 text-uppercase">Entrar</a></small></p>
                        </div> -->
                    </div>
                </div>
            </div>

            <Modal ref="modal" />

        </div>
    <!-- FIM CONTENT -->  

      
    
</template>

<script>

import AuthRelogioSeguroAPI from '@/services/relogioseguroapi/auth'
import Modal from '@/components/Modal'

export default {
    data(){
        return {
            form: { 
                user: {
                    email: '',
                    password: '',
                },
            },
            errorText: ''
        }
    },
    components: {
        Modal
    },
    methods: {
        login(){

            if (!(this.form.user.email.trim() != '' && this.form.user.password.trim() != '')){
                return false;
            }

            this.$refs.modal.isLoading = true;

            AuthRelogioSeguroAPI.login(this.form).then(response => {
                localStorage.setItem('access_token', response.data.access_token)
                AuthRelogioSeguroAPI.me().then(response => {
                    let userLogged = JSON.stringify(response.data);
                    localStorage.setItem('userLogged', userLogged)
                    this.$store.state.userLogged = userLogged
                    if (this.$route.name != 'home'){
                        this.$refs.modal.isLoading = false
                        this.$router.push('home')
                    }
                }).catch(e => {
                    console.log(e)
                    localStorage.removeItem('access_token')
                    localStorage.removeItem('userLogged')
                    this.errorText = "Ocorreu um erro ao carregar informações do usuário, tente mais tarde."
                    this.$refs.modal.isLoading = false
                })
            }).catch(e => {
                localStorage.removeItem('access_token')
                localStorage.removeItem('userLogged')
                if(e.response.status == 401){
                    this.errorText = e.response.data.error
                }else{
                     this.errorText = "Ocorreu um erro desconhecido, tente mais tarde."
                }
                this.$refs.modal.isLoading = false
            })
        },
    },
    computed: {
        isDisabledLogin(){
            if (this.form.user.email.trim() != '' && this.form.user.password.trim() != ''){
                 return false;
            }
            return true;
        },
    },
    mounted: function () {
        this.$nextTick(function () {
            if (JSON.parse(localStorage.getItem('userLogged') != null)) {
                this.$refs.modal.isLoading = false
                this.$router.push('home')
            }else{
                this.$refs.modal.isLoading = false
            }
        }
    )},
}
</script>