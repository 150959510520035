<template>
    <div>
        <div class="d-md-flex justify-content-between align-items-center mb-3">
            <h2 class="display-6 mb-0">Minha Conta</h2>
        </div>  
        <div>
            <div ref="topPage">
                <div v-if="hasErrors" class="alert alert-danger" role="alert">
                    <div v-for="(v, k) in errors" :key="k">
                        <small>{{v.join(' ')}}</small>
                    </div>
                </div>

                <form @submit.prevent="update()">
                    <label>Avatar</label>
                    <div class="cropper-wrapper">
                        <cropper
                            @change="change"
                            minWidth = 500
                            minHeight = 500
                            :src="image"
                            :stencil-props="{
                                handlers: {},
                                //movable: false,
                                scalable: false,
                                resizable: false,
                            }"
                            :stencil-size="{
                                width: 280,
                                height: 280
                            }"
                            image-restriction="stencil"      
                        />
                    </div>
                    <div class="button-wrapper">
                        <span class="button mb-2" @click="$refs.file.click()">
                            <input type="file" ref="file" @change="loadImage($event)" accept="image/*">
                            Trocar Avatar
                        </span>
                    </div>
                    <div class="form-floating mb-2">
                        <input v-model="form.user.email" type="email" maxlength="128" class="form-control form-control-sm" required autofocus>
                        <label>E-Mail</label>
                    </div>                                
                    <div class="form-floating mb-2">
                        <input v-model="form.user.name" type="text" maxlength="128" class="form-control form-control-sm" required autofocus>
                        <label>Nome</label>
                    </div>
                    <div class="form-floating mb-2">
                        <input v-model="form.user.full_name" type="text" maxlength="128" class="form-control form-control-sm" required autofocus>
                        <label>Nome Completo</label>
                    </div>
                    <div class="form-floating mb-2">
                        <the-mask v-model="form.user.cpf" mask="###.###.###-##" type="text" class="form-control form-control-sm" autofocus></the-mask>
                        <label>CPF</label>
                    </div>
                    <div class="form-floating mb-2">
                        <the-mask v-model="form.user.phone" :mask="['(##) ####-####', '(##) #####-####']" type="text" class="form-control form-control-sm" autofocus></the-mask>
                        <label>Telefone</label>
                    </div>
                    <div class="form-floating mb-2">
                        <select v-model="form.user.address.id_uf" class="form-control form-control-sm">
                            <option value="null">Selecione um estado</option>
                            <option v-for="uf in ufs" :key="uf.id" v-bind:value="uf.id">
                                {{uf.name}}
                            </option>
                        </select>
                        <label >Estado</label>
                    </div>
                    <div class="form-floating mb-2">
                        <select v-model="form.user.address.id_city" class="form-control form-control-sm">
                            <option value="null">Selecione uma cidade</option> 
                            <option v-for="city in cities" :key="city.id" v-bind:value="city.id">
                                {{city.name}}
                            </option>
                        </select>
                        <label>Cidade</label>
                    </div>

                    <div class="row p-0 px-md-4 m-0 g-2 text-center">
                        <div class="col d-flex">
                            <button type="submit" class="btn btn-dark btn-sm p-2 flex-fill">Salvar</button>
                        </div>
                    </div>
                    <!-- <div class="form-group">
                        <button type="submit" class="btn btn-primary btn-sm">Salvar</button>
                    </div> -->
                </form>
            </div>
        </div>
        <Modal ref="modal"/>
    </div>
</template>

<script>

import UserRelogioSeguroAPI from '@/services/relogioseguroapi/user'
import AddressRelogioSeguroAPI from '@/services/relogioseguroapi/address'
import Modal from '@/components/Modal'
import {mask} from 'vue-the-mask'
import {TheMask} from 'vue-the-mask'
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

export default {
    directives: {
        mask,
        Cropper
    },
    components: {
        TheMask,
        Modal
    },
    data(){
        return {
            form:{
                user:{
                    name: '',
                    full_name: '',
                    email: '',
                    cpf: '',
                    phone: '',
                    address:{
                        id_uf: '',
                        id_city: ''
                    },
                    avatar: null,
                }
            },
            ufs: [],
            cities: [],
            image: require('@/assets/images/avatar_default.png'),
            imageDefault: require('@/assets/images/avatar_default.png'),
            errors: null,
        }
    },
    methods: {
        goToTopPage(){
            let element = this.$refs['topPage'];
            let top = element.offsetTop;
            window.scrollTo(0, top);
        },
        update(){
            this.$refs.modal.isLoading = true
            UserRelogioSeguroAPI.update(this.form).then(response => {
                this.form.user = response.data;
                if (this.form.user.id_image_avatar){
                    this.image = response.data.image.path
                }else{
                    this.image = require('@/assets/images/avatar_default.png')
                }
                let userLogged = { 
                    "id": response.data.id, 
                    "name": response.data.name, 
                    "avatar": response.data.image ? response.data.image.path : null
                }
                this.$nextTick(function() {this.$store.state.userLogged = userLogged})
                localStorage.setItem('userLogged', JSON.stringify(userLogged))
                this.errors = null
                this.goToTopPage()
                this.$refs.modal.isLoading = false
                this.$refs.modal.isSuccessful = true
            }).catch(e => {
                this.isSuccessful = false
                console.log(e)
                if(e.response){
                    this.errors = e.response.data.error
                }else{
                    this.errors = [['Ocorreu um erro desconhecido.']]
                }
                this.goToTopPage()
                this.$refs.modal.isLoading = false
            })
        },
        change({canvas}) {
            if (this.image != this.imageDefault){
                if (canvas.width > 500){
                    let img = new Image()
                    img.src = canvas.toDataURL('image/png')
                    let canvas2 = document.createElement('canvas')
                    let ctx = canvas2.getContext('2d')
                    canvas2.width = 500;
                    canvas2.height = 500;
                    ctx.drawImage(canvas, 0, 0, 500, 500)
                    this.form.user.avatar = canvas2.toDataURL('image/png')
                }else{
                    this.form.user.avatar = canvas.toDataURL('image/png')
                }
            }else{
                this.form.user.avatar = null;
            }
        },
        reset() {
			this.image = null;
		},
		loadImage(event) {
			var input = event.target;
			if (input.files && input.files[0]) {
				var reader = new FileReader();
				reader.onload = (e) => {
					this.image = e.target.result;
				};
				reader.readAsDataURL(input.files[0]);
			}
		},
    },
    computed: {
        hasErrors() {
            if(this.errors){
                return true
            }
            return false
        }
    },
    watch: {
        'form.user.address.id_uf': function (newValue) {
            
            this.$refs.modal.isLoading = true;

            if(this.auxiliar > 0){
                this.form.user.address.id_city = null;
            }

            AddressRelogioSeguroAPI.cities(newValue).then(response => {
                this.cities = response.data;        
                this.$refs.modal.isLoading = false;
                this.auxiliar = 1;
            })
        }
    },
    mounted: function () {
        this.$refs.modal.isLoading = true
        UserRelogioSeguroAPI.show().then(response => { 
            
            this.auxiliar = 0;
            this.form.user = response.data
            
            if (this.form.user.id_image_avatar){
                this.image = response.data.image.path;
            }

            AddressRelogioSeguroAPI.ufs().then(response => {
                
                this.ufs = response.data;

                if(this.form.user.address.id_city != null){
                    AddressRelogioSeguroAPI.cities(this.form.user.address.id_uf).then(response => {
                        this.cities = response.data;
                        this.$refs.modal.isLoading = false;
                    })
                }else{
                    this.$refs.modal.isLoading = false;
                }
            })

        }).catch(e => {
            console.log(e)
            this.errors = [['Ocorreu um erro desconhecido.']]
            this.goToTopPage()
            this.$refs.modal.isLoading = false
        })
    },
}
</script>

<style scoped>

.upload-example-cropper {
	border: solid 1px #EEE;
	height: 300px;
	width: 100%;
}

.button-wrapper {
	display: flex;
	/* justify-content: center; */
	margin-top: 17px;
}

.button {
	font-size: 16px;
	padding: 10px 20px;
	background-color: #dce6ed;
	cursor: pointer;
	transition: background 0.5s;
    color: #000;
    border-color: #dce6ed;
}

.button:hover {
	color: #000;
    background-color: #e1eaf0;
    border-color: #e0e9ef;
}

.button input {
	display: none;
}

.cropper-wrapper {
	overflow: hidden;
	position: relative;
	height: 500px;
	background: black;
}



</style>