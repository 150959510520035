<template>
    <div v-if="isVisible" >
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                            </div>
                            <div class="modal-body pt-0" id="modalBody"> 

                                <div v-if="hasErrors" class="alert alert-danger" role="alert">
                                    <div v-for="(v, k) in errors" :key="k">
                                        <small>{{v.join(' ')}}</small>
                                    </div>
                                </div>

                                <div class="justify-content-center">
                                    
                                    <div v-if="showWizardStepLoading" >    
                                        <div class="d-flex justify-content-center">
                                            <div class="spinner-border" role="status">
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-center">
                                            <p>{{textLoading}}</p>
                                        </div>
                                    </div>

                                    <form v-if="showWizardStepSearch" @submit.prevent="search()">

                                        <div class="text-center mb-3">
                                            <img src="assets/img/ilustras/rs-ilustra-consulta-lg.svg" class="rs-ilustra-lg rs-ilustra-cor-1" alt="">
                                        </div>
                                        <h6 class="modal-title text-center display-6 mb-2 mb-sm-3" id="modalConsultaLabel">Consultar posse</h6>


                                        <div class="form-floating mb-2">
                                            <select v-model="form.watch.id_brand" class="form-control">
                                                <option value="">Selecione uma Marca</option>
                                                <option v-for="brand in brands" :key="brand.id" v-bind:value="brand.id">
                                                    {{brand.name}}
                                                </option>
                                            </select>
                                            <label >Marca</label>
                                        </div>
                                        
                                        <div class="form-floating mb-2">
                                            <input v-model="form.watch.serial" type="text" maxlength="128" class="form-control form-control-sm" required autofocus>
                                            <label>Serial</label>
                                        </div>
                                        
                                        <div class="row p-0 px-md-4 m-0 g-2 text-center">
                                            <div class="col d-flex">
                                                <button v-on:click="hide()" type="button" class="btn btn-secondary btn-sm p-2 flex-fill">Cancelar</button>
                                            </div>
                                            <div class="col d-flex">
                                                <button type="submit" class="btn btn-dark btn-sm p-2 flex-fill">Buscar</button>
                                            </div>
                                        </div>

                                    </form>

                                    <div v-if="showWizardStepResult" >
                                        <div class="text-center mb-3">
                                            <img src="assets/img/ilustras/rs-ilustra-consulta-lg.svg" class="rs-ilustra-lg rs-ilustra-cor-1" alt="">
                                        </div>
                                        <!-- <h6 class="modal-title text-center display-6 mb-2 mb-sm-3" id="modalConsultaLabel">Resultado da consulta</h6> -->
                                        <h6 class="modal-title text-center display-6 mb-2 mb-sm-3" id="modalConsultaLabel">{{textResult}}</h6>
                                        <div class="row p-0 px-md-4 m-0 g-2 text-center">
                                            <div class="col d-flex">
                                                <button v-on:click="hide()" type="button" class="btn btn-dark btn-sm p-2 flex-fill">OK</button>
                                            </div>  
                                        </div>                                        
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>    
</template>

<script>
import WatchRelogioSeguroAPI from '@/services/relogioseguroapi/watch'

export default {
    data(){
        return {
            form:{
                watch:{
                    id_brand: '',
                    serial: '',
                    image: null,
                },
            },
            brands: [],
            errors: null,
            currentwizardStep: 'search',
            textLoading: 'Aguarde ...',
            textResult: null,
            visible: false
        }
    },
    methods: {
        search(){
            this.currentwizardStep = 'loading'
            WatchRelogioSeguroAPI.searchWatchStatus(this.form).then(response => {
                this.textResult = 'A situação do relógio é: ' + response.data.watch_status
                this.currentwizardStep = 'result'
            }).catch(e => {
                    if(e.response){
                        if(e.response.status == 404){
                            this.textResult = 'Nada Consta.'
                        }else{
                            this.textResult = 'Ocorreu um erro na busca.'
                            if (Array.isArray(e.response.data.error)){
                                this.errors = e.response.data.error
                            }else{
                                this.errors = [[e.response.data.error]]
                            }
                        }
                    }else{
                        this.textResult = 'Ocorreu um erro na busca.'
                        this.errors = [['Ocorreu um erro desconhecido.']]
                    }
                this.currentwizardStep = 'result'
            })
        },
        hide(){
            this.form.watch.serial = ''
            this.error = null
            this.currentwizardStep = 'search'
            this.visible = false
            this.textResult = null
        },        
    },
    computed: {
        hasErrors(){
            if(this.errors){
                return true
            }
            return false
        },
        hasImage(){
            if (this.form.watch.image){
                return true
            }
            return false
        },
        showWizardStepSearch(){
            if(this.currentwizardStep == 'search'){
                return true
            }
            return false
        },
        showWizardStepResult(){
            if(this.currentwizardStep == 'result'){
                return true
            }
            return false
        },
        showWizardStepLoading(){
            if(this.currentwizardStep == 'loading'){
                return true
            }
            return false
        },
        isVisible(){
            return this.visible
        }
    },
    watch: {
        'visible': function (newValue) {
            if (newValue){
                document.getElementsByTagName('body')[0].classList.add('modal-open');
            }else{
                document.getElementsByTagName('body')[0].classList.remove('modal-open');
            }
        },
    },
    mounted: function () {
        this.currentwizardStep == 'loading'
        WatchRelogioSeguroAPI.brands().then(response => {
            this.brands = response.data
        }).catch(e => {
            console.log(e)
            this.errors = [['Ocorreu um erro desconhecido.']]
            this.currentwizardStep == 'result'
        })
    },
}
</script>