<template>

    <div>
        <div class="d-md-flex justify-content-between align-items-center mb-3">
            <h2 class="display-6 mb-0">Confirmação de e-mail</h2>
        </div>  
        <div class="" ref="topPage">
            <div v-if="hasErrors" class="alert alert-danger" role="alert">
                <div v-for="(v, k) in errors" :key="k">
                    <small>{{v.join(' ')}}</small>
                </div>
            </div>
        </div>
        <Modal ref="modal"/>
    </div>



    <!-- <div class="container">
    <h1>Confirmação de e-mail</h1>
        <div class="card">
            <div class="card-body" ref="topPage">
                <div v-if="hasErrors" class="alert alert-danger" role="alert">
                    <div v-for="(v, k) in errors" :key="k">
                        <small>{{v.join(' ')}}</small>
                    </div>
                </div>
            </div>
        </div>
        <Modal ref="modal"/>
    </div> -->
</template>

<script>

import UserRelogioSeguroAPI from '@/services/relogioseguroapi/user'
import Modal from '@/components/Modal'

export default {
    data(){
        return {
            errors: null
        }
    },
    components: {
        Modal
    },
    computed: {
        hasErrors() {
            if(this.errors){
                return true
            }
            return false
        }
    },
    mounted: function () {
        this.$refs.modal.isLoading = true
        if(this.$route.query.id_user && this.$route.query.token){
            UserRelogioSeguroAPI.conformMail(this.$route.query.id_user, this.$route.query.token).then(response => {
                console.log(response)
                this.errors = null
                this.$refs.modal.isLoading = false
                this.$refs.modal.routeRedirect = 'login'
                this.$refs.modal.isSuccessful = true
            }).catch(e => {
                console.log(e)
                if(e.response){
                    if (Array.isArray(e.response.data.error)){
                        this.errors = e.response.data.error
                    }else{
                        this.errors = [[e.response.data.error]]
                    }
                }else{
                    this.errors = [['Ocorreu um erro desconhecido.']]
                }
                this.$refs.modal.isLoading = false
            })
        }else{
            this.errors = [['Token e usuário não encontrados, copie e cole no navegador o link completo enviado por e-mail.']]
            this.$refs.modal.isLoading = false
        }
    },
}
</script>