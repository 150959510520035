import { http } from './config';

export default{
    login:(user) => {
        return http.post('auth/login', user)
    },
    logout:() => {
        let response = http.post('auth/logout')
        localStorage.removeItem('access_token')
        localStorage.removeItem('userLogged')
        return response
    },
    me:() => {
        return http.get('auth/me')
    },
}