<template>
    <div>
        <!-- Modal Aguarde -->
        <div v-if="showLoading" >
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-dialog modal-sm">
                            <div class="modal-content">
                                <div class="modal-body">
                                    <div class="d-flex justify-content-center">
                                        <div class="spinner-border" role="status">
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-center">
                                        <p>{{textLoading}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <!-- Modal Sucesso -->
        <div v-if="showSuccessful" >
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-dialog modal-sm">
                            <div class="modal-content">
                                <div class="modal-body" style="padding: 0.5rem;">
                                    <div class="d-flex justify-content-center mb-3 mt-2">
                                        <i class="far fa-check-circle text-success fa-3x"></i>
                                    </div>
                                    <div class="text-center">
                                        {{textSuccessful}}
                                    </div>
                                </div>
                                <div class="row p-0 px-md-4 m-0 g-2 text-center">
                                    <div class="col d-flex">
                                        <button v-on:click="hideSuccessful()" type="button" class="btn btn-dark btn-sm p-2 flex-fill">OK</button>
                                    </div>
                                </div>
                                <div class="modal-footer" style="padding: 0.5rem;">
                                    <!-- <button v-on:click="hideSuccessful()" type="button" class="btn btn-dark">OK</button> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div> 
    </div>
</template>

<script>
export default {
    data() {
        return {
            isLoading : false,
            isSuccessful: false,
            textSuccessful: 'E-mail enviado para sua caixa de entrada',
            textLoading: 'Aguarde ...',
            routeRedirect: null,
        }
    },
    computed: {
        showLoading() {
            return this.isLoading
        },
        showSuccessful() {
            return this.isSuccessful
        }
    },
    methods : {
        hideSuccessful(){
            this.isSuccessful = false
            if (this.routeRedirect) {
                this.$router.push(this.routeRedirect)
            }
        }
    },
    watch: {
        'visible': function (newValue) {
            if (newValue){
                document.getElementsByTagName('body')[0].classList.add('modal-open');
            }else{
                document.getElementsByTagName('body')[0].classList.remove('modal-open');
            }
        },
    }
}
</script>