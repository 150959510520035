<template>
    <!-- INICIO MAIN ------------------------------------- -->
    <section class="">
        <!-- <?php include('parts/navs/nav-sidebar.php'); ?> -->
        <!-- INICIO CONTENT -->
        <div class="col p-0" ref="topPage">
            <div class="d-md-flex justify-content-between align-items-center mb-3">
                <h2 class="mb-0">Parceiros</h2>
            </div>
            <div class="row g-2 rs-seguranca-mob">
                <div v-for="partner in partners" :key="partner.id" v-bind:value="partner.id" class="col-4 col-md-3 col-lg-2">
                    <!-- <div class="row"> -->
                        <!-- <div class="col-4 col-md-3 col-lg-2"> -->
                            <!-- CARD PARCEIRO -->
                            <div class="card text-center border-0 p-1 rs-hover">
                                <div class="card-body p-0">
                                    <a :href="partner.website" class="stretched-link"><img :src="partner.image.path" class="img-fluid" alt="descrição dea imagem" title="Onlife"></a>
                                    {{partner.name}}
                                </div>                                          
                            </div>
                        <!-- </div> -->
                    <!-- </div> -->
                </div>
            </div>
            <div class="container-fluid py-4 py-sm-5">
            <!-- CTA  -->
            <section class="">
                <div class="container">
                <div class="row justify-content-center rs-seguranca-mob">
                    <div class="col-md-6 d-md-flex text-center justify-content-center">
                    <p>
                        Mais parceiros estão chegando. <br/> Siga <a href="#" class="text-dark">@relogioseguro</a> no Instagram e fique atualizado.
                    </p>      
                    </div>
                </div>
                </div>
            </section>
            </div>
        </div>
        <!-- FIM CONTENT -->
        <Modal ref="modal"/>
    </section>
    <!-- FIM MAIN  -->


   
</template>

<script>

import PartnerRelogioSeguroAPI from '@/services/relogioseguroapi/partner'
import Modal from '@/components/Modal'


export default {
    data(){
        return {
            partners: [],
            
        }
    },
    directives: {
        
    },
    components: {
        Modal
    },
    methods: {

    },
    mounted: function () {
        this.$refs.modal.isLoading = true
        PartnerRelogioSeguroAPI.list().then(response => {
            console.log(response.data)
            this.partners = response.data
            this.$refs.modal.isLoading = false
        }).catch(e => {
            console.log(e)
            if(e.response){
                if (Array.isArray(e.response.data.error)){
                        this.errors = e.response.data.error
                    }else{
                        this.errors = [[e.response.data.error]]
                    }
                }else{
                    this.errors = [['Ocorreu um erro desconhecido.']]
            }
            //this.goToTopPage()
            this.$refs.modal.isLoading = false
        })
    },
    
    
    
}
</script>

<style scoped>

.upload-example-cropper {
	border: solid 1px #EEE;
	height: 250px;
	width: 100%;
}

.button-wrapper {
	display: flex;
	/* justify-content: center; */
	margin-top: 17px;
}

.button {
	font-size: 16px;
	padding: 10px 20px;
	background-color: #dce6ed;
	cursor: pointer;
	transition: background 0.5s;
    color: #000;
    border-color: #dce6ed;
}

.button:hover {
	color: #000;
    background-color: #e1eaf0;
    border-color: #e0e9ef;
}

.button input {
	display: none;
}


</style>